import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "reusecore/Layout";
import PageHeader from "reusecore/PageHeader";
import { DocsPageWrapper } from "./docsGrid.style";
import { Avatar, Card } from "antd";

const DocsLanding = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            products {
              name
              description
              docsLink
              logo
            }
          }
        }
      }
    `,
  );
  const products = site.siteMetadata.products;

  return (
    <DocsPageWrapper>
      <PageHeader title="Documentation" subtitle="Welcome to Sortd's Documentation Hub. Choose your product below to continue:"/>

      <div className="blog-page-wrapper">
        
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <div className="blog-grid-wrapper">
                <Row>
                  {products.map(product => (
                    <Col xs={12} sm={6}>
                      <Link to={product.docsLink}>
                          <Card 
                            hoverable
                            style={{marginBottom: 12}}
                          >
                            <Card.Meta
                                    avatar={<Avatar src={product.logo}/>}
                                    title={product.name}
                                    description={product.description}
                            />
                        </Card>
                        </Link>
                        </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </DocsPageWrapper>
  );
};

export default DocsLanding;
