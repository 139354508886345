import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "components/layout"
import SEO from "components/seo"

import Navigation from "sortd_sections/landing/Navigation"
import BlogPage from "sortd_sections/doc"
import Footer from "sortd_sections/shared/Footer"

import { GlobalStyle } from "sortd_sections/shared/app.style"
import theme from "theme/blog/themeStyles"

const BlogGridPage = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <GlobalStyle />
      <SEO title="Sortd Blog" />
      <BlogPage />
    </Layout>
  </ThemeProvider>
)
export default BlogGridPage
